<template>
  <section>
    <ContentCard data-auto-id="token-add" title="Add Client">
      <div class="py-8 px-4">
        <p class="mb-8" :class="disabled ? 'opacity-25' : ''">
          Create clients below by entering a name and associating that client to
          a registration token. You can also create clients using any of our
          SDKs. Learn more by reading our API Documentation
        </p>
        <hr class="border-gray-light border-t-2 mb-8" />
        <ClientForm
          @submit="(e) => $emit('submit', e)"
          @cancel="$emit('cancel')"
          :loading="loading"
          :error="errorMessage"
          :tokens="tokens"
          :disabled="disabled"
        />
      </div>
    </ContentCard>
  </section>
</template>

<script>
import ContentCard from '../../../Common/ContentCard'
import ClientForm from './ClientForm'
export default {
  name: 'AddClient',
  components: {
    ContentCard,
    ClientForm,
  },
  props: {
    loading: Boolean,
    errorMessage: String,
    tokens: Array,
    disabled: Boolean,
  },
}
</script>
