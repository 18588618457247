<template>
  <section>
    <ContentCard data-auto-id="client-list" title="Clients">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="client-card"
        />
      </template>
      <div class="pt-8 px-4 pb-2">
        <p>Select a client to view available actions.</p>
        <ItemTable
          data-auto-id="client-table"
          :items="clients"
          :columns="tableColumns"
          layout-fixed
          click-row
          @rowClick="handleRowClick"
          :has-more="hasMore"
          :loading="loading"
          @more="$emit('more')"
        >
          <template #name="{ itemData }">
            <div class="truncate text-semibold" :title="itemData">
              {{ itemData }}
            </div>
          </template>
          <template #clientId="{ itemData }">
            <span class="text-tiny text-gray-dark">{{ itemData }}</span>
          </template>
          <template #backup="{ itemData }">
            <div class="text-center">
              <i class="material-icons micon text-gray-dark">{{
                itemData ? 'cloud_queue' : 'cloud_off'
              }}</i>
            </div>
          </template>
          <template #status="{ itemData }">
            <div class="text-center">
              <StatusDot
                :status="itemData"
                :auto-id="`client-status-${itemData}`"
              />
            </div>
          </template>
          <template #icon>
            <i class="material-icons micon text-gray-dark"
              >keyboard_arrow_right</i
            >
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import Actions from '../../../Common/Actions'
import ContentCard from '../../../Common/ContentCard'
import ItemTable from '../../../Common/ItemTable'
import StatusDot from '@/Common/StatusDot'
export default {
  name: 'TokenList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
    StatusDot,
  },
  props: {
    clients: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (typeof item.name !== 'string') {
            return false
          }
          if (typeof item.clientId !== 'string' || !item.clientId) {
            return false
          }
          if (typeof item.enabled !== 'boolean') {
            return false
          }
          if (typeof item.hasBackup !== 'boolean') {
            return false
          }
        }
        return true
      },
    },
    errorMessage: String,
    loading: Boolean,
    hasMore: Boolean,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Client Name',
          target: 'name',
          slot: 'name',
          className: 'w-1/3',
        },
        {
          title: 'Associated Client ID',
          target: 'clientId',
          slot: 'clientId',
        },
        {
          title: 'Backup',
          target: 'hasBackup',
          slot: 'backup',
          className: 'w-1/6 text-center',
        },
        {
          title: 'Status',
          target: 'enabled',
          slot: 'status',
          className: 'w-1/6 text-center',
        },
        {
          title: '',
          target: '',
          slot: 'icon',
          className: 'w-10',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'add client',
          icon: 'add',
        },
      ]
    },
  },
  methods: {
    handleCardActions(action) {
      this.$emit(action.event)
    },
    handleRowClick(client) {
      this.$router.push(`/clients/${client.clientId}`)
    },
  },
}
</script>
