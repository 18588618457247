<template>
  <MainLayout>
    <Banner
      v-if="showTokenBanner"
      type="red"
      button-text="Create Token"
      :click-method="toTokenPage"
    >
      <h3 class="font-semibold mb-2">Registration Token Needed</h3>
      <p class="mb-0">
        Registration Token needed before you can create a client.
      </p>
    </Banner>
    <div
      v-if="status === 'initializing' || (status === 'loading' && !initialized)"
      class="w-full flex items-center justify-center"
    >
      <ToznyLoader class="text-tozny w-24 h-24" />
    </div>
    <Banner
      v-else-if="status === 'error'"
      type="red"
      button-text="Reload"
      :click-method="initialize"
    >
      <p class="mb-0">{{ errorMessage }}</p>
    </Banner>
    <section v-else-if="zeroState">
      <NoClients
        :disabled="disabled"
        :loading="status === 'zero.adding'"
        :tokens="tokens"
        :errorMessage="errorMessage"
        @submit="registerClient"
      />
    </section>
    <AddClient
      v-else-if="addState"
      :loading="status === 'add.adding'"
      :tokens="tokens"
      :errorMessage="errorMessage"
      :disabled="disabled"
      @submit="registerClient"
      @cancel="transitionStatus('idle')"
    />
    <section v-else>
      <ClientList
        :clients="clients"
        :loading="status === 'loading'"
        :has-more="hasMore"
        @more="fetchNextPage"
        @add="transitionStatus('add')"
      />
    </section>
  </MainLayout>
</template>

<script>
// @ is an alias to /src
import MainLayout from '@/Common/MainLayout/MainLayout'
import Banner from '@/Common/Banner'
import ToznyLoader from '@/Common/ToznyLoader'
import NoClients from './Components/NoClients'
import ClientList from './Components/ClientList'
import AddClient from './Components/AddClient'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'clients',
  components: {
    MainLayout,
    Banner,
    ToznyLoader,
    NoClients,
    ClientList,
    AddClient,
  },
  computed: {
    ...mapState('clients', ['status', 'errorMessage', 'initialized']),
    ...mapState('tokens', ['tokens']),
    ...mapGetters('clients', ['clients', 'disabled', 'hasMore']),
    zeroState() {
      return this.status.split('.')[0] === 'zero'
    },
    addState() {
      return this.status.split('.')[0] === 'add'
    },
    showTokenBanner() {
      return (this.zeroState || this.addState) && this.disabled
    },
  },
  methods: {
    ...mapActions('clients', [
      'initialize',
      'fetchNextPage',
      'registerClient',
      'transitionStatus',
    ]),
    toTokenPage() {
      this.$router.push('/tokens')
    },
  },
  created: async function () {
    await this.initialize()
  },
}
</script>
